import { Canvas } from "@react-three/fiber";
import { RotationAndFlexion } from "../3DModals/RotationAndFlexion";
import { Environment, OrbitControls } from "@react-three/drei";
import { AmbientLight } from "three";
import { useState } from "react";

const RotationAndFlexionClass = () => {
    const [name, set_name] = useState('')
    const handelbutton = (name) => {
        set_name(name)
    }
    console.log("object", name)

    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <Canvas style={{ height: '80vh', width: '100vw' }}>
                <OrbitControls />
                <Environment preset="sunset"></Environment>
                <mesh>
                    <RotationAndFlexion name={name} />
                </mesh>
            </Canvas>
            <div style={{ flexDirection: 'row', marginTop: 10, flex: 1 }}>
                <button
                    style={{
                        marginRight: 10,
                        padding: '10px 20px',
                        backgroundColor: '#007BFF',
                        color: '#FFFFFF',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                    onClick={() => handelbutton('Rotation')}
                >
                    Rotation
                </button>

                <button
                    style={{
                        marginLeft: 10,
                        padding: '10px 20px',
                        backgroundColor: '#007BFF',
                        color: '#FFFFFF',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                    onClick={() => handelbutton('Flexion')}
                >
                    Flexion
                </button>
            </div>

        </div>
    )
}

export default RotationAndFlexionClass;
