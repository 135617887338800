import React, { useEffect, useRef, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export function RotationAndFlexion({ name, ...props }) {
  console.log("props", name)
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/Rotation and Flexion_04.glb')
  const { actions } = useAnimations(animations, group)
  const [current_action, set_current_action] = useState(null)

  useEffect(() => {
    if (name && actions[name]) {
      if (current_action) {
        current_action.fadeOut(0.5).stop(); // Stop the current action if it exists
        resetMorphTargets()
      }

      actions[name].reset().fadeIn(0.5).play();
      set_current_action(actions[name]);

    }
  }, [name, actions, current_action]);
  const resetMorphTargets = () => {
    const morphTargetInfluences = nodes.Cylinder001.morphTargetInfluences;
    for (let i = 0; i < morphTargetInfluences.length; i++) {
      morphTargetInfluences[i] = 0; // Reset all morph targets to 0 influence
    }
  };
  useFrame((state) => {
    const flexionAction = actions[name];
    if (!flexionAction) return;
    const flexionTime = flexionAction.time; // Current time of the flexion animation
    const morphTargetDictionary = nodes.Cylinder001.morphTargetDictionary;
    const morphTargetInfluences = nodes.Cylinder001.morphTargetInfluences;
    const bisIndex = morphTargetDictionary['Bis'];
    const key2Index = morphTargetDictionary['Key 2'];
    const minInfluence = 0.2; // Minimum influence value to avoid flickering

    if (name === 'Flexion' && bisIndex !== undefined) {
      // Animate 'Bis' influence based on flexionTime
      morphTargetInfluences[bisIndex] = Math.max(minInfluence, Math.abs(Math.sin(flexionTime))); // Example: using sine function for smooth animation
    }
    if (name === 'Rotation' && key2Index !== undefined) {
      // Animate 'Key 2' influence based on flexionTime
      morphTargetInfluences[key2Index] = Math.max(minInfluence, Math.abs(Math.sin(flexionTime))); // Example: using cosine function for smooth animation
    }
  });
  return (
    <group ref={group} {...props} dispose={null} scale={[0.4, 0.4, 0.4]} >
      <group name="Scene">
        <group name="Armature" position={[0, 8.013, 0]}>
          <skinnedMesh
            name="Cylinder"
            geometry={nodes.Cylinder.geometry}
            material={materials['Material.001']}
            skeleton={nodes.Cylinder.skeleton}
          />
          <skinnedMesh
            name="Cylinder001"
            geometry={nodes.Cylinder001.geometry}
            material={materials['Material.002']}
            skeleton={nodes.Cylinder001.skeleton}
            morphTargetDictionary={nodes.Cylinder001.morphTargetDictionary}
            morphTargetInfluences={nodes.Cylinder001.morphTargetInfluences}
          />
          <primitive object={nodes.Bone} />
        </group>
      </group>
    </group>
  )
}
