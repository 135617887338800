import './App.css';
import RotationAndFlexionClass from './components/RotationAndFlexionClass';

function App() {
  return (
    <div className="App">
    <RotationAndFlexionClass/>
    </div>
  );
}

export default App;
